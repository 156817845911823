<template>
  <div class="LoanApplicationManagement">
    <!-- 申请记录公司详情 -->
    <ShowDetail v-if="showDetail" @closeDialog="closeDialog" ref="showDetail" />
    <!-- 申请记录农户详情 -->
    <ShowDetail2 v-if="showDetail2" @closeDialog2="closeDialog2" ref="showDetail2" />
    <!-- 申请记录审批 -->
    <ShowEdit v-if="showEdit" @closeDialog3="closeDialog3" ref="showEdit" />
    <div class="form-list">
      <div class="table-search">
        <h3>运营查看用户申请</h3>
        <!-- 根据条件搜索 -->
        <el-form class="" :model="form" label-position="right" label-width="80px" inline ref="form">
          <el-form-item label="单号：">
            <el-input style="width: 190px" v-model="form.applyId" placeholder="单号">
            </el-input>
          </el-form-item>
          <el-form-item label="产品名称：">
            <el-input style="width: 190px" v-model="form.title" placeholder="产品名称">
            </el-input>
          </el-form-item>
          <el-form-item label="申请人地址：">
            <el-cascader ref="cascader" style="width: 190px" v-model="form.areaCode" :props="props"
              :options="regionOptions" :show-all-levels="false"></el-cascader>
          </el-form-item>
          <el-form-item label="申请人：">
            <el-input style="width: 190px" v-model="form.name" placeholder="申请人">
            </el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select v-model="form.status" style="width: 200px">
              <el-option v-for="(item, index) in types" :key="index" :value="item.value"
                :label="item.label"></el-option></el-select>
          </el-form-item>
          <el-form-item label="批准时间：">
            <el-date-picker v-model="form.time" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" size="small" value-format="yyyy-MM-dd" @change="changeTime">
            </el-date-picker>
          </el-form-item>
          <div>
            <el-button class="query-btn" type="primary" @click="search">查询</el-button>
            <el-button class="query-btn" type="primary" @click="clear">清空</el-button>
            <el-button class="query-btn" type="primary" @click="exportExcel">导出Excel</el-button>
          </div>
        </el-form>
        <!-- 金融产品表格 -->
        <div class="table-content">
          <table-page :data="tableData" :columns="columns" :currentPage="query.pnum" :pageSize="query.psize"
            :total="total" :loading="loading" :border="true" @sizeChange="handleSizeChange"
            @currentChange="handleCurrentChange" @selectionChange="handleSelection">
            <template slot="orderType" slot-scope="scope">
              <span>{{
                scope.row.orderType == 1 ? "全程托管" : "环节托管"
                }}</span>
            </template>
            <template slot="status" slot-scope="scope">
              <span v-if="scope.row.status == '-1'">驳回</span>
              <span v-if="scope.row.status == '0'">草稿</span>
              <span v-if="scope.row.status == '1'">审批中</span>
              <span v-if="scope.row.status == '2'">通过</span>
            </template>

            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small"
                @click="detailClick(scope.row.applyId, scope.row.userType)">查看</el-button>
              <el-button type="text" size="small" @click="editClick(scope.row.applyId, scope.row.status)">标注</el-button>
            </template>
          </table-page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TablePage from "@/components/table/table-page";
  import ShowDetail from "./ApplicationRecordDetails";
  import ShowDetail2 from "./FarmerApplicationRecordDetails";
  import ShowEdit from "./ShowEdit";
  import { wytgColumn } from "./tableColumn";
  import {
    ApplicationRecordList,
    AreaTree,
    exportExcel,
  } from "@/api/RuralFinance";
  import { mapGetters } from "vuex";
  export default {
    name: "AgriculturalAssistanceProject9Index",

    data() {
      return {
        tableData: [],
        columns: wytgColumn,
        showDetail: false,
        showDetail2: false,
        showEdit: false,
        loading: false,
        keyForm: 1,
        total: 0,
        query: {
          pnum: 1,
          psize: 10,
        },

        form: {
          applyId: "",
          title: "",
          name: "",
          areaCode: "",
          status: "",
          time: ["", ""],
        },
        regionOptions: [], //地区
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          fullPathName: "fullPathName",
          checkStrictly: true,
          emitPath: false,
        },
        types: [
          {
            label: "全部",
            value: "",
          },
          {
            label: "驳回",
            value: "-1",
          },
          {
            label: "草稿",
            value: "0",
          },
          {
            label: "审批中",
            value: "1",
          },
          {
            label: "通过",
            value: "2",
          },
        ],
      };
    },
    components: {
      TablePage,
      ShowDetail,
      ShowDetail2,
      ShowEdit,
    },

    mounted() {
      this.getList();
      this.AreaTree();
    },
    computed: {
      ...mapGetters(["identityObj", "topSelectAreacode"]),
    },
    methods: {
      //获取贷款信息
      async getList() {
        this.loading = true;
        let params = {
          pnum: this.query.pnum,
          psize: this.query.psize,
          areaCode: this.identityObj.areaCode,
        };
        let res = await ApplicationRecordList(params);
        if (!res.code) {
          // this.tableData = res.data;
          this.total = res.dataNum;
          this.loading = false;
        } else {
          this.$message.error("查询失败");
          this.loading = false;
        }
        this.tableData = res.data;
        this.total = res.dataNum;
      },
      //点击查看详情
      detailClick(applyId, userType) {
        if (userType == 2) {
          this.showDetail2 = true;
          this.$nextTick(() => {
            this.$refs.showDetail2.init(applyId);
          });
        } else {
          this.showDetail = true;
          this.$nextTick(() => {
            this.$refs.showDetail.init(applyId);
          });
        }
      },
      //点击编辑按钮
      editClick(applyId, status) {
        this.showEdit = true;
        this.$nextTick(() => {
          this.$refs.showEdit.init(applyId, status);
        });
      },
      // 关闭公司详情弹框
      closeDialog() {
        this.showDetail = false;
      },
      // 关闭农户详情弹框
      closeDialog2() {
        this.showDetail2 = false;
      },
      // 关闭修改弹框
      closeDialog3() {
        this.showEdit = false;
        this.getList();
      },
      //筛选时间
      changeTime(val) {
        this.startTime = val[0];
        this.endTime = val[1];
        // this.initData();
        console.log(this.startTime, this.endTime);
      },
      //所属区域
      async AreaTree() {
        let params = {
          parentCode: this.identityObj.areaCode,
        };
        let res = await AreaTree(params);
        this.regionOptions = res.data;
      },
      handleSelection(selects) {
        console.log(selects);
      },
      //分页单页切换
      handleCurrentChange(page) {
        this.query.pnum = page;
        this.getList();
      },
      //分页总页数切换
      handleSizeChange(size) {
        this.query = {
          pnum: 1,
          psize: size,
        };
        this.getList();
      },

      // 点击搜索按钮
      async search() {
        this.query.pnum = 1;
        let params = {
          ...this.query,
          ...this.form,
          approveStartTime: this.startTime,
          approveEndTime: this.endTime,
          // areaCode: this.form.areaCode,
        };
        console.log(params, "查看运营");
        let res = await ApplicationRecordList(params);
        this.tableData = res.data;
        this.total = res.dataNum;
      },
      //地址切换

      //清空重置按钮
      async clear() {
        (this.form.applyId = ""),
          (this.form.title = ""),
          (this.form.name = ""),
          (this.form.areaCode = ""),
          (this.form.status = ""),
          (this.form.time = ["", ""]);
        let params = {
          ...this.query,
        };

        let res = await ApplicationRecordList(params);
        this.tableData = res.data;
        this.total = res.dataNum;
      },
      //导出excel表格
      async exportExcel() {
        let params = {
          name: this.form.name,
          status: this.form.status,
          approveStartTime: this.startTime,
          approveEndTime: this.endTime,
          departName: this.form.departName,
          title: this.form.title,
          areaCode: this.identityObj.areaCode,
          personDivision: this.form.areaCode,
        };
        let res = await exportExcel(params);
        if (res) {
          let blob = res;
          const fileName = "用户申请表格.xlsx";
          let downloadElement = document.createElement("a");
          let binaryData = [];
          binaryData.push(blob);
          let href = window.URL.createObjectURL(
            new Blob(binaryData, { type: "application/pdf" })
          ); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = decodeURIComponent(fileName); //解码
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      },
      submit() { },
    },
  };
</script>

<style lang="less" scoped>
  /deep/.el-form--inline {
    display: flex;
    flex-wrap: wrap;
  }

  /deep/.el-form-item {
    width: 25% !important;

    .el-input {
      width: 200px;
    }

    .el-form-item__label {
      width: 100px !important;
    }

    .el-form-item__content {
      width: 200px;
    }

    .el-date-editor {
      width: 200px;
    }
  }

  .form-list {
    .table-search {
      h3 {
        margin-left: 20px;
      }
    }
  }
</style>
